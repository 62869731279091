<template>
	<div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {}
	},
	created() {
		ElMessage.error('非法进入!')
	},
	methods: {}
}
</script>

<style>

</style>